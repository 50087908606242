import React from 'react';
import '../App.css';
import './VoteSignal.css';

export default class VoteSignal extends React.Component {
      
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
    });
  }

  render() {
    let tod = ((new Date()).getTime() / 1000) - this.props.data.t;
    let expiry = Math.min(this.props.data.l, 30, tod);
    let o = 1 - (expiry / this.props.data.l);
    //let o = 1;

    //var signalData = this.props.data;
    /*
    if (window.innerWidth <= 600) {
      var temp = signalData.x;
      signalData.x = signalData.y;
      signalData.y = temp;
    }*/

    if ((parseFloat(this.props.data.y/100) * this.state.windowHeight) < 100) {
      return <></>;
    }

    return (
      <div 
        className="vote-signal"
        style={{
          left: parseFloat(this.props.data.x/100) * this.state.windowWidth, 
          top: parseFloat(this.props.data.y/100) * this.state.windowHeight,
          opacity: parseFloat(o),
          backgroundColor: "#" + this.props.data.c,
        }}
      ></div>
    );  
  }
};
import React from 'react';
import VoteSignal from '../components/VoteSignal';
import ClickOverlay from '../components/ClickOverlay';
import '../App.css';
import './Slider.css';

export default class Slider extends React.Component {
  render() {
    return (
      <div className="poll-panel">        
        <div className={"poll-title"}>{this.props.config.data.title}</div>
        <div className={"poll-options-container slider-poll"}>

        {
            this.props.votes.map((e,i) => {
                return <VoteSignal key={`vote_${i}`} data={e}/>
            })
        }            

        <div className="slider-container">
            <div className="slider-labels">
                <div className="option-title title-0">&nbsp; {this.props.config.data.options[0]}</div>
                <div className="option-title title-100">{this.props.config.data.options[1]} &nbsp;</div>
            </div>
        </div>

        </div>
        <ClickOverlay hoseId={this.props.hoseId} votingRight={this.props.votingRight} onVoted={this.props.onVoted}/>
      </div>
    );  
  }
};
import React from 'react';
import VoteSignal from '../components/VoteSignal';
import ClickOverlay from '../components/ClickOverlay';
import '../App.css';
import './Bubbles.css';

export default class Bubbles extends React.Component {
  render() {
    return (
      <div className="poll-panel">        
        <div className={"poll-title"}>{this.props.config.data.title}</div>
        <div className={"poll-options-container"}>

        {
            this.props.votes.map((e,i) => {
                return <VoteSignal key={`vote_${i}`} data={e}/>
            })
        }            

        {
            (this.props.config.data.options.length === 1) && 
            <>
                <div className="poll-option one-option">{this.props.config.data.options[0]}</div>
            </>
        }

        {
            (this.props.config.data.options.length === 2) && 
            <>
                <div className="poll-option two-option">{this.props.config.data.options[0]}</div>
                <div className="poll-option two-option">{this.props.config.data.options[1]}</div>
            </>
        }

        {
            (this.props.config.data.options.length === 3) && 
            <>
                <div className="poll-option three-option">{this.props.config.data.options[0]}</div>
                <div className="poll-option three-option">{this.props.config.data.options[1]}</div>
                <div className="poll-option three-option">{this.props.config.data.options[2]}</div>
            </>
        }        

        {
            (this.props.config.data.options.length === 4) && 
            <>
                <div className="poll-option four-option">{this.props.config.data.options[0]}</div>
                <div className="poll-option four-option">{this.props.config.data.options[1]}</div>
                <div className="poll-option four-option">{this.props.config.data.options[2]}</div>
                <div className="poll-option four-option">{this.props.config.data.options[3]}</div>
            </>
        }

        </div>
        <ClickOverlay hoseId={this.props.hoseId} votingRight={this.props.votingRight} onVoted={this.props.onVoted}/>
      </div>
    );  
  }
};
import React from 'react';
import Config from "./config";
import Networker from "./utils/Networker";

import PollTwoColumn from './screens/PollTwoColumn';
import Bubbles from './screens/Bubbles';
import Slider from './screens/Slider';

import './App.css';

export default class App extends React.Component {

  constructor(props) {
    super(props);
    let suppliedHoseId = document.location.hash.replace("#","").substring(0, 36);
    this.state = {
      session_code: "fhs",
      hose_id: suppliedHoseId, // a412fe12-8913-417f-8dbe-c045df4d1b99
      votes: [],
      config: false,
      last_refresh: false,
      voting_right: false,
    }
    this.refreshTimer = null;
    this.refreshHoseData = this.refreshHoseData.bind(this);
  }

  refreshHoseData() {
    if (this.state.session_code === "") return;
    if (!this.state.voting_right) return;
    Networker.loadJSON(`${Config.api_server}/session/${this.state.session_code}/${this.state.voting_right}`).then((data) => {
      this.setState({votes:data.votes, config: data.config, hose_id: data.hose_id, last_refresh: new Date()});
    })
  }

  componentDidMount() {

    this.refreshTimer = setInterval(() => { this.refreshHoseData() }, 2000);
      
    // First things first: let's get ourselves a voting right
    Networker.loadJSON(`${Config.api_server}/dealer/voting_right`).then((data) => {
      if (data.status) {
        this.setState({voting_right: data.voting_right}, () => {
          this.refreshHoseData();
        });
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  render() {
    return (
      <div className="app">        
        {
          this.state.config && this.state.config.type === "two_column" && 
          <PollTwoColumn 
            hoseId={this.state.hose_id} 
            votingRight={this.state.voting_right} 
            config={this.state.config} 
            votes={this.state.votes}
            onVoted={()=>{this.refreshHoseData()}}
          />
        }
        {
          this.state.config && this.state.config.type === "bubbles" && 
          <Bubbles 
            hoseId={this.state.hose_id} 
            votingRight={this.state.voting_right} 
            config={this.state.config} 
            votes={this.state.votes}
            onVoted={()=>{this.refreshHoseData()}}
          />
        }
        {
          this.state.config && this.state.config.type === "slider" && 
          <Slider 
            hoseId={this.state.hose_id} 
            votingRight={this.state.voting_right} 
            config={this.state.config} 
            votes={this.state.votes}
            onVoted={()=>{this.refreshHoseData()}}
          />
        }

      </div>
    );  
  }
};
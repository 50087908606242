//import Config from "../config";

export default class Networker {

  static handlePotentialError(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  static loadJSON(url) {
    let anticacheUrl = url;
    if (url.indexOf("?")>0) {
      anticacheUrl = url + "&ac=" + (new Date()).getTime();
    } else {
      anticacheUrl = url + "?ac=" + (new Date()).getTime();
    }
    var that = this;
    return new Promise(function(resolve, reject) {
      fetch(anticacheUrl, { })
        .then(that.handlePotentialError)
        .then(response => { resolve(response.json()); } )
        .catch(error => { console.log("[Networker] Error: ", error); reject(error); } );
    });
  }

  static postJSON(url, params) {
    var that = this;
    var jsonData = JSON.stringify(params);
    return new Promise(function(resolve, reject) {
      fetch(
          url, { 
          method: 'POST', 
          headers: { "Content-type": "application/json"}, 
          body: jsonData,
        })
        .then(that.handlePotentialError)
        .then(response => { resolve(response.json()); } )
        .catch(error => { console.log(error); reject(error); } );
    });
  }

}
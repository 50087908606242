import React from 'react';
import Config from "../config";
import Networker from '../utils/Networker';

import './ClickOverlay.css';

export default class ClickOverlay extends React.Component {
      
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
    this.registerClick = this.registerClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
    });
  }

  getMyColor(forceNew=false) {
//    if (localStorage.getItem("ccc") && !forceNew) {
 //     return localStorage.getItem("ccc");
 //   } else {
      let color = Math.floor(Math.random()*16777215).toString(16);
      localStorage.setItem("ccc", color);
      return color;
//    }
  }  

  registerClick(e) {
    let pX = e.pageX / window.innerWidth;
    let pY = e.pageY / window.innerHeight;
    var payload = {
      x: Math.round(pX * 100), 
      y: Math.round(pY * 100), 
      voting_right: this.props.votingRight,
      color: this.getMyColor(true),
    };
    // Rotate/flip on mobile...
    /*
    if (window.innerWidth <= 600) {
      payload.x = Math.round(pY * 100);
      payload.y = Math.round(pX * 100);
    }
    */
    Networker.postJSON(`${Config.api_server}/vote/${this.props.hoseId}`, payload)
    .then((data) => {
      this.props.onVoted();
    })
  }

  render() {
    return (
      <div 
        onClick={this.registerClick}
        className="click-overlay" 
        style={{width: this.state.windowWidth, height: this.state.windowHeight}}></div>
    );  
  }
};
import React from 'react';
import VoteSignal from '../components/VoteSignal';
import ClickOverlay from '../components/ClickOverlay';
import '../App.css';
import './PollTwoColumn.css';

export default class PollTwoColumn extends React.Component {
  render() {
    return (
      <div className="poll-panel">        
        <div className={"poll-title"}>{this.props.config.data.title}</div>
        <div className={"poll-options-container"}>
          <div className={"poll-option left-poll-option"}>{this.props.config.data.left_column}</div>
          <div className={"poll-option right-poll-option"}>{this.props.config.data.right_column}</div>
          {
            this.props.votes.map((e,i) => {
              return <VoteSignal key={`vote_${i}`} data={e}/>
            })
          }
        </div>
        <ClickOverlay hoseId={this.props.hoseId} votingRight={this.props.votingRight} onVoted={this.props.onVoted}/>
      </div>
    );  
  }
};